import React from 'react';
import styled from 'styled-components';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';

import HydraHeader from './HydraHeader';

import gsp from '../images/Referenz_01.jpg';
import car2go from '../images/Referenz_02.jpg';
import stapler from '../images/Referenz_03.jpg';

import ChevronRight from '@material-ui/icons/ChevronRightSharp';
import ScrollTrigger, { ScrollFader } from './ScrollTigger';
import { useMedia } from 'react-use';
import { motion } from 'framer-motion';

import avatarImg from '../images/Avatar 1.svg';
import avatarAltImg from '../images/Avatar 2.svg';

import quoteMark from '../images/quote.svg';
import bgStartQuote from '../images/bg_quote_start.svg';
import bgEndQuote from '../images/bg_quote_end.svg';
import starsBg from '../images/stars.jpg';

const Section5 = styled.section`
  margin-top: -4px;

  .apps {
    display: flex;
  }

  .content div {
    flex: 1;
  }

  .content {
    display: flex;
  }

  @media screen and (max-width: 1200px) {
    .apps {
      flex-direction: column;
      align-items: center;
    }
  }
`;

const Section5andHalf = styled.section`
  margin-top: -4px;

  background-image: url(${starsBg});
  margin-top: -4px;
  background-repeat: repeat-y;

  &::after {
    display: none;
  }
`;

const QuoteWrap = styled.div`
  display: flex;

  background-image: url(${bgStartQuote});
  background-repeat: no-repeat;
  background-size: 9em;

  padding: 2em 3.5em;

  &.endBgQuote {
    background-image: url(${bgEndQuote});
    background-position: 100% 100%;
  }

  .avatar {
    background-image: url(${p => p.altAvatar ? avatarAltImg : avatarImg});
    width: 4em;
    height: 4em;
    min-width: 4em;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .startQuoteMark, .endQuoteMark {
    background-image: url(${quoteMark});
    width: 1.5em;
    height: 1.5em;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: inline-block;
  }

  .startQuoteMark {
    margin-right: 20px;
    margin-bottom: -0.60em;
  }

  .endQuoteMark {
    transform: rotateZ(180deg);
    align-self: flex-end;
    margin-left: 1em;
    margin-bottom: -0.25em;
  }

  .quoteContent {
    max-width: 1200px;
    margin-left: 2em;
    position: relative;
    z-index: 20;
  }

  .quoteText {
    ${'' /* display: flex; */}
    
  }

  .actualQuote {
    padding: 1em;
  }

  .quoteAuthor {
    font-size: 0.85em;
    margin-top: 10px;
  }

  @media (max-width: 700px) {
    padding: 1em 2.5em;

    .avatar {
      width: 3em;
      height: 3em;
      min-width: 3em;
      margin-top: 0.35em;
    }
  }

`;

const Quote = ({ children, author, altAvatar, bgQuotePos = 'start' }) => {
  const className = `${bgQuotePos}BgQuote`;

  return (
    <QuoteWrap altAvatar={altAvatar !== undefined && altAvatar !== false} className={className}>
      <div className="avatar" />
      <div className="quoteContent">
        <div className="quoteText">
          <div className="startQuoteMark" />
          {children}&nbsp;
          <div className="endQuoteMark" />
        </div>
        <div className="quoteAuthor">{author}</div>
      </div>
    </QuoteWrap>
  );
};

const RandomFloatOnDesktop = styled.div`

  @media screen and (min-width: 1200px) {
    display: flex;
    justify-content: flex-end;

    > div {
      max-width: 900px;
    }
  }

`;

const AppWrap = styled.div`
  flex: 1;
  max-width: 400px;
  margin: 40px;

  &:last-child {
    margin-right: 0;
  }

  &:first-child {
    margin-left: 0;
  }

  .customer, h3 {
    font-weight: bold;
  }

  h3 {
    height: 80px;
    margin-top: 2px;
    font-weight: normal;
  }

  img {
    width: 100%;
  }

  p {
    margin-top: 2px;
  }

  @media screen and (max-width: 1200px) {
    max-width: 800px;
    margin: 40px 0;

    h3 {
      height: auto;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const AppCTAWrap = styled.a`
  background-color: #33FF00;
  padding: 3px 6px;
  color: black;
  font-weight: bold;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  padding-left: 14px;

  .MuiSvgIcon-root {
    margin-left: 8px;
  }
`;

const AppCTA = ({ ...props }) => {
  return <AppCTAWrap {...props}>
    <FormattedMessage id="ctaMore" />
    <ChevronRight />
  </AppCTAWrap>
};

const appAnimation = {
  hidden: {
    scale: 0.8,
    x: 20,
    opacity: 0
  },
  visible: { scale: 1, x: 0, opacity: 1 },
};

const App = ({ children, ...props }) => {
  return (
    <AppWrap {...props}>
      <motion.div variants={appAnimation}>
        {children}
      </motion.div>
    </AppWrap>
  );
}

export default ({ ...props }) => {
  const isWide = useMedia('(min-width: 1200px)');
  const { formatMessage } = useIntl();

  return (
    <>
      <Section5 {...props}>
        <HydraHeader><FormattedMessage id="refsHeader" /></HydraHeader>

        <ScrollFader>
          <p><FormattedMessage id="refsSubline" /></p>
        </ScrollFader>

        <ScrollTrigger threshold={isWide ? 0.4 : 0.1}>
          <div className="apps content">
            <App>
              <div className="customer"><FormattedMessage id="refs1customer" /></div>
              <h3><FormattedMessage id="refs1project" /></h3>
              <img src={gsp} alt="" />
              <p><FormattedMessage id="refs1text" /></p>
              <AppCTA target="_blank" rel="noopener noreferrer" href={formatMessage({ id: 'refs1href' })} />
            </App>

            <App>
              <div className="customer"><FormattedMessage id="refs2customer" /></div>
              <h3><FormattedMessage id="refs2project" /></h3>
              <img src={car2go} alt="" />
              <p><FormattedMessage id="refs2text" /></p>
              <AppCTA target="_blank" rel="noopener noreferrer" href={formatMessage({ id: 'refs2href' })} />
            </App>

            <App>
              <div className="customer"><FormattedMessage id="refs3customer" /></div>
              <h3><FormattedMessage id="refs3project" /></h3>
              <img src={stapler} alt="" />
              <p><FormattedMessage id="refs3text" /></p>
              <AppCTA target="_blank" rel="noopener noreferrer" href={formatMessage({ id: 'refs3href' })} />
            </App>
          </div>
        </ScrollTrigger>
      </Section5>
      <Section5andHalf className="before-fade">
      <div style={{textAlign: 'center'}}>
          <h2 className="small" style={{ marginTop: '3em' }}>
            <FormattedMessage id="quotesHeader" />
          </h2>
        </div>
        <ScrollFader threshold={0.3}>
          <div className="detail-content">
            <Quote author={formatMessage({ id: 'quotes1author' })}>
              <FormattedMessage id="quotes1text" />
            </Quote>
          </div>
          <div className="detail-content">
            <RandomFloatOnDesktop>
              <Quote author={formatMessage({ id: 'quotes2author' })} altAvatar bgQuotePos="end">
                <FormattedMessage id="quotes2text" />
              </Quote>
            </RandomFloatOnDesktop>
          </div>
        </ScrollFader>
      </Section5andHalf>
    </>
  );
};