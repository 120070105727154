import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'gatsby-plugin-intl';

import bulbBg from '../images/bulb.jpg';
import rocket from '../images/rocket_white.svg';
import HydraHeader from './HydraHeader';
import { ScrollFader } from './ScrollTigger';

const Section1 = styled.section`
  background-image: url(${bulbBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -320px 0;

  .side {
    position: relative;
    margin-top: 90px;
  }

  .rocket {
    width: 84px;
    position: absolute;
    top: -52px;
    left: -52px;
  }

  .main .inner {
    max-width: 60%;
  }

  @media screen and (max-width: 1000px) {
    .side p {
      max-width: 60%;
    }
  }

  @media screen and (min-width: 1000px) {
    display: flex;
    justify-content: space-between;
    height: 850px;
    /* background-position: -11220px 0; */

    .rocket {
      width: 128px;
      top: -100px;
      left: -100px;
    }

    .main {
      flex: 1;
    }
    
    .main .inner {
      max-width: 680px;
    }

    .side {
      flex: 1;
      max-width: 420px;
      align-self: flex-end;
      margin-top: 0;
      margin: 0 40px;
    }

    .small-on-desktop {
      font-size: 30px;
    }
  }

  @media screen and (min-width: 1400px) {
    height: 1080px;
  }

  @media screen and (max-width: 600px) {
    background-position: -320px 0;
    .rocket {
      width: 84px;
      top: -100px;
      left: -10px;
    }

    .side {
      margin-top: 120px;
    }
  }

  @media screen and (max-width: 400px) {
    background-position: -350px 0;
  }
`;

const WhiteHeadline = styled.h2`
  display: inline-block;
  background-color: white;
  padding: 1px 12px;
  margin: 5px 0;
  color: black;
  font-size: 40px;
  line-height: 68px;

  @media screen and (max-width: 1200px) {
    font-size: 20px;
    line-height: 34px;
  }
`;


export default ({ ...props }) => (
  <Section1 {...props}>
    <div className="main">
      <div className="inner">
        <HydraHeader>
          <FormattedMessage
            id="prosHeader"
            values={{
              smallondesktop: text => <div style={{ marginTop: 5 }} className="hy small-on-desktop">{text}</div>,
            }} />
        </HydraHeader>

        <ScrollFader delay={0.3}>
          <p>
            <FormattedMessage
              id="prosTruthis"
              values={{
                highlight: text => <b>{text}</b>,
                break: text => <br />,
              }} />
          </p>
        </ScrollFader>
      </div>
    </div>

    <div className="side">
      <ScrollFader>
        <img className="rocket" src={rocket} alt="" />
        <WhiteHeadline><FormattedMessage id="prosAgileHeader" /></WhiteHeadline>
        <br />
        <WhiteHeadline><FormattedMessage id="prosAgileHeaderPt2" /></WhiteHeadline>

        <p><FormattedMessage id="prosAgileText" /></p>
      </ScrollFader>
    </div>
  </Section1>
);