import React from 'react';
import styled from 'styled-components';
import { useIntersection, useMedia } from 'react-use';
import { motion } from 'framer-motion';
import { Lottie } from '@crello/react-lottie';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';
import Button from './cta';

import HydraHeader from './HydraHeader';

import starsBg from '../images/stars.jpg';
import frank from '../images/Frank_portrait.jpg';
import downloadAnimation from '../lottie/download.json';
import ScrollTrigger from './ScrollTigger';

const Section6 = styled.section`
  background-image: url(${starsBg});
  margin-top: -49px;
  padding-top: 40px;
  background-repeat: repeat-y;
  box-shadow: 0 0 transparent !important;

  img {
    display: block;
  }

  .telCTA {
    display: block;
    font-size: 60px;
    margin-bottom: 0.5em;
    text-decoration: none;
    font-weight: bold;
  }

  .mailCTA {
    display: block;
    font-size: 35px;
    text-decoration: underline;
  }

  .contact {
    display: flex;
    margin-top: 1.5em;
  }

  .contact img {
    max-height: 410px;
  }

  .contact .content {
    margin-left: 60px;
    align-self: flex-end;
  }

  .pdfDownloadWrap {
    margin-top: 120px;
    display: flex;
    justify-content: center;
  }

  .pdfDownload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    text-decoration: none;
  }

  .pdfDownload .icon {
    display: block;
    margin-bottom: 10px;
    width: 142px;
  }

  .mobile, .mobileFrank {
    display: none;
  }

  @media screen and (max-width: 1200px) {
    .telCTA {
      font-size: 36px;
      margin-bottom: 0.25em;
    }

    .mailCTA {
      font-size: 20px;
    }

    .contact {
      margin-top: 3em;
    }
  }

  @media screen and (max-width: 900px) {
    .content.content {
      margin: 0;
      align-self: auto;
    }
    .contact {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .mobile {
      display: block;
    }
    .desktop {
      display: none;
    }
    .desktopFrank {
      display: none;
    }
    .mobileFrank {
      display: inline-block;
      width: 98px;
      margin-bottom: 16px;
    }
    .contactDetails {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .contact img {
      max-width: 120px;
    }
  }

  @media screen and (max-width: 600px) {

    .pdfDownload .icon {
      width: 82px;
    }
  }

`;

const desktopFrank = {
  hidden: { opacity: 0, x: -15, scale: 0.85 },
  visible: { opacity: 1, x: 0, scale: 1 },
};

export default ({ onTelVisiblityChange, ...props }) => {
  const isWide = useMedia('(min-width: 1200px)');
  const [intersecting, setIntersecting] = React.useState(false);
  const intersectionRef = React.useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1
  });
  const { locale, formatMessage } = useIntl();

  if (intersection && (intersecting !== intersection.isIntersecting)) {
    setIntersecting(intersection.isIntersecting);
    onTelVisiblityChange(intersection.isIntersecting);
  }

  const contentVariants = {
    hidden: { opacity: 0, x: isWide ? 15 : 0 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <ScrollTrigger threshold={0.5}>
      <Section6 id="contact-area" {...props}>
        <HydraHeader><FormattedMessage id="contactHeader" /></HydraHeader>
        
        <div className="contact">
          <motion.div variants={desktopFrank}>
            <img className="desktopFrank" src={frank} alt="" />
          </motion.div>
          <div className="content">
            <motion.div variants={contentVariants}>
              <div className="contactDetails">
                <img className="mobileFrank" src={frank} alt="" />
                <div style={{ marginBottom: 30, fontWeight: 'bold' }}>
                  <FormattedMessage id="contactName" />
                </div>
                <a ref={intersectionRef} href={formatMessage({ id: 'contactTelHref' })} className="telCTA">
                  <FormattedMessage id="contactTel" description="Contact Telephone Number" />
                </a>
                <a href={formatMessage({ id: 'contactEmailHref' })} className="mailCTA">
                  <FormattedMessage id="contactEmail" />
                </a>

                <div style={{ marginTop: 30, fontWeight: 'bold' }}>
                  <FormattedMessage id="contactUs" />
                </div>

                <div style={{ marginTop: 30 }}>
                  <Button
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://www.hydra-newmedia.com/technologie-agile-software-entwicklung/?lang=${locale}`}
                  >
                    <FormattedMessage id="aboutButton" />
                  </Button>
                </div>
              </div>
            </motion.div>
          </div>
        </div>

        <div className="pdfDownloadWrap">
          <a href={formatMessage({ id: 'downloadHref' })} className="pdfDownload">
            <div className="icon">
              <Lottie config={{ animationData: downloadAnimation, loop: true, autoplay: true }} />
            </div>
            <FormattedMessage id="downloadAsPdf" />
          </a>
        </div>
      </Section6>
    </ScrollTrigger>
  );
};