import React from 'react';
import styled from 'styled-components';

import martin from '../images/martin.jpg';
import taas2 from '../images/TAAS.svg';
import ScrollTrigger from './ScrollTigger';
import { motion } from 'framer-motion';

const Section2 = styled.section`
  background-image: url(${martin});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 -150px;

  padding: 50px;

  img {
    max-width: 60vw;
  }

  @media screen and (max-width: 1200px) {
    /* height: 240px; */
    background-position: right 0;
  }

  @media screen and (max-width: 800px) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 240px;
    background-position: right 0;
  }

  @media screen and (max-width: 500px) {
    height: 135px;
  }
`;

const logo = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

export default ({ ...props }) => (
  <Section2 {...props}>
    <ScrollTrigger threshold={0.8}>
      <motion.div variants={logo}>
        <img src={taas2} alt="" />
      </motion.div>

    </ScrollTrigger>
  </Section2>
);