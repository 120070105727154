import React, { useState, useRef } from 'react';
import { useIntersection, useMedia } from 'react-use';
import { motion } from 'framer-motion';

const ScrollTrigger = ({ children, threshold = 1 }) => {
  const [intersecting, setIntersecting] = useState(false);
  const intersectionRef = useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold,
  });

  if (intersecting !== true && intersection && intersection.isIntersecting !== false) {
    setIntersecting(true);
  }

  return (
    <motion.div 
      ref={intersectionRef}
      initial={false}
      animate={intersecting ? 'visible' : 'hidden'}
      variants={{ visible: { transition: {staggerChildren: 0.1} } }}
      >
        {children}
      </motion.div>
  );
};

export const ScrollFader = ({ children, threshold, delay }) => {
  const isWide = useMedia('(min-width: 1200px)');
  const fadeVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0, transition: { delay } },
  };

  if (threshold === undefined && !isWide) {
    threshold = 0.2;
  }

  return (
    <ScrollTrigger threshold={threshold}>
      <motion.div variants={fadeVariants}>
        {children}
      </motion.div>
    </ScrollTrigger>
  )
};

export default ScrollTrigger;
