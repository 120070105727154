import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import ScrollTrigger from './ScrollTigger';

const HydraHeaderWrap = styled.div`
  margin-top: 2em;

  .line {
    width: 6em;
    background-color: white;
    height: 10px;
  }

  h2 {
    margin-top: 32px;
    margin-bottom: 0;
  }

  @media screen and (max-width: 1400px) {
    h2 {
      margin-top: 20px;
    }

    .line {
      height: 6px;
    }
  }

  @media screen and (max-width: 600px) {
    .line {
      height: 4px;
      width: 70px;
    }
  }
`;

const line = {
  hidden: { opacity: 0, scaleX: 0 },
  visible: { opacity: 1, scaleX: 1 },
};

const headline = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0, transition: { delay: 0.1 } },
};

const HydraHeader = ({ children }) => {
  return (
    <ScrollTrigger>
      <HydraHeaderWrap>
        <motion.div className="line"
          variants={line}
        />
        <motion.h2 variants={headline}>
          {children}
        </motion.h2>
      </HydraHeaderWrap>
    </ScrollTrigger>
  );
};

export default HydraHeader;