import * as React from "react";
// import { motion, useViewportScroll, useTransform } from "framer-motion";
import styled from 'styled-components';

import ChevronRight from '@material-ui/icons/ChevronRightSharp';

const AppCTAWrap = styled.a`
  background-color: #33FF00;
  padding: 3px 6px;
  color: black;
  font-weight: bold;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  padding-left: 14px;

  .MuiSvgIcon-root {
    margin-left: 8px;
  }
`;

const AppCTA = ({ children, ...props }) => {
  return <AppCTAWrap {...props}>
    {children}
    <ChevronRight />
  </AppCTAWrap>
};

export default AppCTA;