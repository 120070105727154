import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'gatsby-plugin-intl';

import HydraHeader from './HydraHeader';
import { ScrollFader } from './ScrollTigger';

const Section3 = styled.section`
  .content {
    display: flex;
  }

  .left {
    flex: 3;
    margin-right: 42px;
  }

  .middle {
    flex: 5;
  }

  .waste {
    flex: 3;
  }

  @media screen and (max-width: 1200px) {
    .waste {
      display: none;
    }
  }

  @media screen and (max-width: 800px) {
    .content {
      flex-direction: column;
    }
  }


`;

export default ({ ...props }) => (
  <Section3 {...props}>
    <HydraHeader><FormattedMessage id="conceptHeader" /></HydraHeader>

    <ScrollFader>
      <div className="content">
        <p className="left">
          <FormattedMessage
            id="conceptLeft"
            values={{
              highlight: text => <span className="hy">{text}</span>,
            }} />
        </p>

        <p className="middle">
          <FormattedMessage
            id="conceptRight"
            values={{
              highlight: text => <span className="hy">{text}</span>,
            }} />
        </p>

        <p className="waste"></p>
      </div>
    </ScrollFader>
  </Section3>
);