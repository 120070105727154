import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'gatsby-plugin-intl';

import LazyLoad from 'react-lazyload';
import { Lottie } from '@crello/react-lottie';
import HydraHeader from './HydraHeader';

import devAnimation from '../lottie/development.json';
import rocketAnimation from '../lottie/rocket_black.json';
import starsBg from '../images/stars.jpg';
import { ScrollFader } from './ScrollTigger';

// frontend images
import reactImg from '../images/logos/React-icon.svg';
import vueImg from '../images/logos/vue_logo.svg';
import babelImg from '../images/logos/Babel_Logo.svg';
import mobxImg from '../images/logos/MobX.svg';
import webpackImg from '../images/logos/webpack.svg';

// backend images
import nodejsImg from '../images/logos/nodejs_logo.svg';
import mongodbImg from '../images/logos/MongoDB_Logo.svg';
import golangImg from '../images/logos/golang_logo.svg';
import redisImg from '../images/logos/redis_logo.svg';
import elasticImg from '../images/logos/Elasticsearch_logo.svg';
import hapiImg from '../images/logos/hapi.svg';
import expressImg from '../images/logos/expressjs_logo.svg';
import mysqlImg from '../images/logos/MySQL_logo.svg';

// infra
import kubeImg from '../images/logos/Kubernetes_logo.svg';
import dockerImg from '../images/logos/Docker_logo.svg';
import googleCloudImg from '../images/logos/google_cloud_logo.svg';
import gitlabCiImg from '../images/logos/gitlab_ci_logo.svg';
import datadogImg from '../images/logos/datadoghq-ar21.svg';

// tools
import gitlabImg from '../images/logos/gitlab_logo.svg';
import npmLogo from '../images/logos/Npm-logo.svg';
import gitLogo from '../images/logos/Git-logo.svg';
import sentryLogo from '../images/logos/sentry_logo.svg';
import seleniumLogo from '../images/logos/Selenium@2x.png';
import slackLogo from '../images/logos/Slack_Technologies_Logo.svg';
import browserstackLogo from '../images/logos/browserstack-logo-vector.svg';

const Section4 = styled.section`
  background: linear-gradient(#33ff00, #000);
  background-image: url(${starsBg});

  margin-top: -4px;

  .content, .detail-content, .tagCloudContent {
    display: flex;
  }

  .tagCloudContent {
    margin: 1.25em 0;
  }

  .content div {
    flex: 1;
  }

  .detail-content > div{
    text-align: left;
  }

  .detail-content ul {
    padding-left: 22px;
  }

  .detail-content li {
    padding-left: 15px;
  }

  @media screen and (max-width: 800px) {
    .content {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 1100px) {
    .detail-content {
      flex-direction: column;
    }

    .tagCloudContent {
      flex-direction: column;
    }
  }


  @media screen and (min-width: 1200px) {
    .content, .detail-content {
      padding-right: 100px;
    }
  }

`;

const FeatureBox = styled.div`
  flex: 1;
  text-align: ${p => p.textAlign || 'center'};
  margin-right: 1em;

  .feature-icon {
    display: inline-block;
    max-height: 6em;
    max-width: 6em;
    width: 6em;
    background-color: #33FF00;
    border-radius: 100%;
    padding: 1.5em;
    margin-top: 80px;
  }

  p {
    margin-top: 0;
  }

  @media screen and (min-width: 1200px) {
    margin-right: 2em;
  }
  
`;

const ImageTags = styled.div`
  img {
    background-color: white;
    padding: 1em 1em;
    height: 2em;
    margin: 0 5px;
  }
`;

export default ({ ...props }) => (
  <Section4 {...props}>
    <HydraHeader><FormattedMessage id="techHeader" /></HydraHeader>
    <ScrollFader>
      <p>
        <FormattedMessage id="techSubline" />
      </p>
    </ScrollFader>
    <ScrollFader threshold={0.3}>
      <div className="content">
        <FeatureBox>
          <div className="feature-icon">
            <LazyLoad once={true} height={147} offset={-220}>
              <Lottie config={{ animationData: devAnimation, loop: false, autoplay: true }} />
            </LazyLoad>
          </div>
          <h3><FormattedMessage id="techDevelopmentHeader" /></h3>
          <p><FormattedMessage id="techDevelopmentText" /></p>
        </FeatureBox>

        <FeatureBox>
          <div className="feature-icon">
            <LazyLoad once={true} height={147} offset={-220}>
              <Lottie config={{ animationData: rocketAnimation, loop: false, autoplay: true }} />
            </LazyLoad>
          </div>
          <h3><FormattedMessage id="techDeploymentHeader" /></h3>
          <p><FormattedMessage id="techDeploymentText" /></p>
        </FeatureBox>
      </div>
    </ScrollFader>

    <h2 className="small" style={{ marginTop: '3em', textAlign: 'center', display: 'block' }}>
      <FormattedMessage id="techStrengthsHeader" />
    </h2>

    <ScrollFader threshold={0.3}>
      <div className="tagCloudContent">
        <FeatureBox textAlign="left">
          <h3><FormattedMessage id="techFrontendHeader" /></h3>
          <ImageTags>
            <img src={reactImg} alt="React" />
            <img src={vueImg} alt="Vue.js" />
            <img src={mobxImg} alt="MobX" />
            <img src={babelImg} alt="Babel" />
            <img src={webpackImg} alt="Webpack" />
          </ImageTags>
          <ul>
            <FormattedMessage
              id="techFrontendList"
              values={{
                li: text => <li>{text}</li>,
              }} />
          </ul>
        </FeatureBox>

        <FeatureBox textAlign="left">
          <h3><FormattedMessage id="techBackendHeader" /></h3>
          <ImageTags>
            <img src={nodejsImg} alt="Node.js" style={{ height: '3em', padding: '0.5em' }} />
            <img src={golangImg} alt="Go" />
            <img src={hapiImg} alt="Hapi" style={{ height: '2.5em', padding: '0.75em' }} />
            <img src={expressImg} alt="Express.js" style={{ height: '1.25em', padding: '1.37em' }} />
            <img src={mongodbImg} alt="MongoDB" />
            <img src={redisImg} alt="Redis" />
            <img src={elasticImg} alt="Elasticsearch" />
            <img src={mysqlImg} alt="MySQL" />
          </ImageTags>
          <ul>
            <FormattedMessage
              id="techBackendList"
              values={{
                li: text => <li>{text}</li>,
              }} />
          </ul>
        </FeatureBox>
      </div>
    </ScrollFader>

    <ScrollFader threshold={0.3}>
      <div className="tagCloudContent">
        <FeatureBox textAlign="left">
          <h3><FormattedMessage id="techInfraHeader" /></h3>
          <ImageTags>
            <img src={kubeImg} alt="Kubernetes" />
            <img src={dockerImg} alt="Docker" />
            <img src={googleCloudImg} alt="Google Cloud" />
            <img src={gitlabCiImg} alt="GitLab CI" />
            <img src={datadogImg} alt="Datadog" />
          </ImageTags>
          <ul>
            <FormattedMessage
              id="techInfraList"
              values={{
                li: text => <li>{text}</li>,
              }} />
          </ul>
        </FeatureBox>

        <FeatureBox textAlign="left">
          <h3><FormattedMessage id="techToolsHeader" /></h3>
          <ImageTags>
            <img src={gitlabImg} alt="GitLab" />
            <img src={npmLogo} alt="npm" />
            <img src={gitLogo} alt="git" />
            <img src={sentryLogo} alt="Sentry" />
            <img src={seleniumLogo} alt="Selenium" />
            <img src={slackLogo} alt="Slack" />
            <img src={browserstackLogo} alt="BrowserStack" />
          </ImageTags>
          <ul>
            <FormattedMessage
              id="techToolsList"
              values={{
                li: text => <li>{text}</li>,
              }} />
          </ul>
        </FeatureBox>
      </div>
    </ScrollFader>

  </Section4>
);