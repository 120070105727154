import React from 'react';

import 'intersection-observer';
import styled, { keyframes } from 'styled-components';
import { Fab } from '@material-ui/core';
import { motion, AnimatePresence } from 'framer-motion';
import { useIntl, FormattedMessage, changeLocale } from 'gatsby-plugin-intl';
import loadable from '@loadable/component';

import Section1 from '../components/section-1';
import Section2 from '../components/section-2';
import Section3 from '../components/section-3';
import Section4 from '../components/section-4';
import Section5 from '../components/section-5';
import Section6 from '../components/section-6';
import SEO from '../components/seo';

import Layout from '../components/layout';
import logo from '../images/hydra_logo.svg';
import taasLogo from '../images/TAAS_white.svg';
import starsBg from '../images/stars.jpg';
import telephone from '../images/telefon.svg';
import hashtags from '../images/hashtag_2.svg';

const Section = loadable(() => import('../components/smart-section'))
const SectionsSpy = loadable(() => import('../components/smart-section-spy'))

const Hero = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  background-image: url(${starsBg});
  background-repeat: repeat-y;
  margin-top: -4px;

  /* min-height: 80vh; */

  p {
    font-size: 1.25em;
  }

  .markerText {
    background-color: white;
    color: black;
    padding: 0 4px;
    font-style: italic;
  }

  .logo {
    margin-top: 50px;
    position: relative;
    z-index: 99;
    width: 160px;
  }

  .taas-logo {
    display: block;
    margin: 80px 0;
    margin-bottom: 40px;
  }

  h1 {
    font-size: 44px;
  }

  .logo, .MuiSvgIcon-root {
    display: block;
  }

  .scrollHelp {
    animation: SCROLL_DOWN_DIGGA 2s linear infinite;
    animation-direction: alternate;
    animation-delay: 5s;
    opacity: 0;
  }

  .content {
    padding: 0 20px;
  }

  .hashtags {
    width: 500px;
    margin-top: 25px;
  }

  @media screen and (max-width: 1600px) {
    .taas-logo {
      width: 500px;
    }

    .hashtags {
      width: 400px;
    }
  }

  @media screen and (min-width: 1200px) {
    .logo {
      align-self: flex-end;
      margin-right: 40px;
      width: 232px;
    }
    .content {
      display: flex;
      align-items: flex-end;
    }

    h1 {
      flex: 1;
      font-size: 120px;
    }

    .content p {
      margin-bottom: 74px;
      margin-left: 50px;
    }
  }

  @media screen and (max-width: 800px) {
    .logo {
      width: 120px;
    }
  }

  @media screen and (max-width: 600px) {
    p {
      max-width: 245px;
    }
  }
`;

const pulsate = keyframes`
  from { box-shadow: 0 0 13px white; }
  to { box-shadow: 0 0 1px white; }
`;

const FloatingNav = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  transform: translateZ(0);
  height: 100%;
  /* width: 64px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, rgba(0,0,0,0), #000);
  padding-right: 20px;
  z-index: 10;

  font-size: 0.8em;

  div {
    width: 21px;
    height: 21px;
    border-radius: 100%;
    background-color: transparent;
    border: 1px solid white;
    margin: 10px 0;
    margin-left: 15px;
    transition: background-color 200ms ease;
  }

  a {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;
    color: white;
  }

  a.special {
    color: #33FF00;
  }

  .special img {
    max-width: 32px;
  }

  span {
    margin-right: 15px;
    transition: opacity 400ms ease, transform 250ms ease;
    opacity: 0;
    transform: translateX(50px);
  }

  .active > div {
    background-color: white;
    animation: ${pulsate} 1s linear infinite;
    animation-direction: alternate;
  }

  .active > span {
    opacity: 1;
    transform: translateX(0px);
  }

  @media screen and (max-width: 1600px) {
    padding-right: 10px;
    div {
      width: 12px;
      height: 12px;
      margin: 8px 0;
    }

    img {
      width: 24px;
      margin-right: -3px;
    }
  }

  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

const HyFabButton = styled(Fab)`
  &.MuiFab-root.MuiFab-root{
    background-color: black;
    box-shadow: 0 0 5px 0 #33ff00a3;
    color: black;
  }

  img {
    width: 50%;
    margin: 10px;
  }
`;

const HyFabWrap = styled(motion.div)`
  position: fixed;
  bottom: 30px;
  right: 30px;
  overflow: hidden;
  contain: content;
  padding: 2px;
  z-index: 15;

  @media screen and (min-width: 1200px) {
    display: none;
  }
`;

const NavEntry = ({ children, ...props }) => {
  return (
    <a {...props}>
      <span>{children}</span>
      <div></div>
    </a>
  );
};

const ContactNavEntry = ({ className, ...props }) => (
  <a className={['special', className].join(' ')} {...props}>
    <span><FormattedMessage id="nav_contact" /></span>
    <img src={telephone} alt="" />
  </a>
);

const LangSwitcherWrap = styled.div`
  position: absolute;
  top: 1em;
  left: 1em;
  font-size: 0.8em;
  display: flex;
  align-items: center;

  a {
    color: white;
    color: #ffffffd6;
  }

  .sep {
    height: 0.25em;
    width: 0.25em;
    background-color: #ffffffa2;
    margin: 0.5em;
    border-radius: 100%;
  }

  a.active {
    color: white;
    font-weight: bold;
  }
`;

const LangSwitcher = () => {
  const { locale } = useIntl();
  const changeTo = (e, lang = 'en') => {
    e.preventDefault();
    changeLocale(lang);
  };
  return (
    <LangSwitcherWrap>
      <a
        onClick={e => changeTo(e, 'de')}
        href="/de/"
        className={locale === 'de' ? 'active' : undefined}
      >
        Deutsch
      </a>
      <div className="sep" />
      <a
        onClick={e => changeTo(e, 'en')}
        href="/en/" 
        className={locale === 'en' ? 'active' : undefined}
      >
        English
      </a>
    </LangSwitcherWrap>
  )
}

export default () => {
  const [telVisible, setTelVisible] = React.useState(false);
  const { locale, formatMessage } = useIntl();

  const handleFab = () => {
    document.getElementById('contact-area').scrollIntoView({ behavior: 'smooth' });
  };

  const scroll = (name) => {
    // window.scrollTo({ top: to + 400, behavior: 'smooth' })
    document.getElementById(name).scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <Layout>
      <SEO 
        lang={locale}
        title={formatMessage({ id: 'seoTitle' })}
        description={formatMessage({ id: 'seoDescription' })}
        keywords={formatMessage({ id: 'seoKeywords' })}
        author={formatMessage({ id: 'seoAuthor' })}
      />
      <AnimatePresence>
        {!telVisible && (
          <HyFabWrap 
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
          >
            <HyFabButton onClick={handleFab} color="primary" aria-label={formatMessage({ id: 'callUs' })}>
              <img src={telephone} alt="" />
            </HyFabButton>
          </HyFabWrap>
        )}
      </AnimatePresence>
      <FloatingNav>
        <SectionsSpy render={sections => (
          sections.map(section => {
            if (section.name === 'contact') return <ContactNavEntry key={section.name} onClick={() => scroll('contact')} className={section.active ? 'active' : null} />;
            return (
              <NavEntry key={section.name} onClick={() => scroll(section.name)} className={section.active ? 'active' : null}>
                <FormattedMessage id={`nav_${section.name}`} />
              </NavEntry>
            );
          })
        )} />
        
      </FloatingNav>

      <Section name="hero" id="hero">
        <Hero>
          <LangSwitcher />
          <img className="logo" alt="hydra newmedia GmbH" src={logo} />

          <div className="content">
            <img className="taas-logo" alt="Team as a Service" src={taasLogo} />
          </div>
          <p>
            <FormattedMessage
              id="heroSubline"
              description="Hero subline text"
              values={{
                highlight: text => <span className="markerText">{text}</span>,
              }}
            />
          </p>
          <img className="hashtags" alt="#FullStack #SoftwareDevelopment #AgileDevelopment #ITOutsourcing" src={hashtags} />
        </Hero>
      </Section>

      <Section name="pros" id="pros">
        <Section1 className="no-fade" style={{ paddingTop: 20 }} />
      </Section>

      <Section name="concept" id="concept">
        <Section2 className="no-fade" />
        <Section3 className="no-fade" />
      </Section>

      <Section name="tech" id="tech">
        <Section4 className="before-fade" />
      </Section>

      <Section name="refs" id="refs">
        <Section5 />
      </Section>

      <Section name="contact" id="contact">
        <Section6 onTelVisiblityChange={setTelVisible} />
        <footer>
          © {new Date().getFullYear()}
          {` `}
          <a target="_blank" rel="noopener noreferrer" href={formatMessage({ id: 'footerCompanyHref' })}>
            <FormattedMessage id="footerCompany" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href={formatMessage({ id: 'footerImpressHref' })}>
            <FormattedMessage id="footerImpress" /> 
          </a>
        </footer>
      </Section>

    </Layout>
  );
};
